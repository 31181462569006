import React, { Component } from "react";
//import { Link, Redirect } from 'react-router-dom';
import Link from "../components/link";
import { connect } from "react-redux";
import { API_ENDPOINT } from "../constants";
import Isvg from "react-inlinesvg";
import icon from "../assets/svg/icon.svg";
import SupportForm from "./forms/supportForm6";
// import chatForm from "./forms/chatForm";
// import chat from '../assets/images/chat.png';
// import ChatForm from '../components/forms/chatForm';
import engflag from '../assets/images/icon-great-britain.png'
import sweflag from '../assets/images/icon-sweden.png'
// import SupportForm from '/forms/supportForm4';

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
} from "reactstrap";

import logo from "../assets/svg/curoflowtelemedicinelogoblack.svg";
import bars from "../assets/svg/bars.svg";
import CustomDropdown from "./customDropdown";
function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}
export class Nav extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      mobileMenu: false,
      yScroll: 0,
      SupportForm: false,
      mobilni: false,
      changeLanguage: "",
      supportForm: false,

      // chatForm: false,
      // showChatIcon: true
      resourcesLinks: [
        {
          link: "/kunders-feedback-pa-Curoflow-digital-vardplattform",
          name: "Customers tell us",
          position: 2,
          special: true,
        },
        {
          link: "/gdpr-efterlevnad-i-Curoflow-digital-vardplattform",
          name: "GDPR compliance".translate(this.props.lang),
          position: 3,
          special: true,
          lang: this.props.lang
        },
        {
          link: "/nyheter-om-curoflow-vardplattform",
          name: "News",
          position: 4,
          special: true,
          lang: this.props.lang
        },
        {
          link: "/artiklar-om-mjukvara-for-digital-vard",
          name: "Articles",
          position: 4,
          special: true,
          lang: this.props.lang
        },
        {
          link: "https://shows.acast.com/curopodden",
          name: "Podcast",
          position: 5,
          special: true,
        },

      ],
      otherAboutUsLinks: [
        // {
        //   link: "/",
        //   name: "About us",
        //   position: 1,
        //   special: true,
        //   lang: this.props.lang
        // },
        {
          link: "/kontakta-oss",
          name: "Contact us",
          position: 1,
          special: true,
          lang: this.props.lang
        },
        {
          link: "https://jobs.curoflow.se/",
          name: "Career",
          position: 4,
          special: true,
        },
        // {
        //   link: "/cookies",
        //   name: "Cookies",
        //   position: 6,
        //   special: true,
        // },
        // {
        //   link: "/cookies",
        //   name: "Cookies",
        //   position: 6,
        //   special: true,
        // },
        // {
        //   link: "/integritetspolicy",
        //   name: "Privacy policy",
        //   position: 7,
        //   special: true,
        // },
        // {
        //   link: "/solution",
        //   name: "Articles",
        //   position: 4,
        //   special: true,
        //   lang: this.props.lang
        // },
        // {
        //   link: "https://shows.acast.com/curopodden",
        //   name: "Podcast",
        //   position: 5,
        //   special: true,
        // },
      ]
    };
  }

  sendMessage17 = (data) => {
    this.props.setRecaptchaCallback(() => {

      if (data && data.checked && this.props.allowSubmit) {
        if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
          return this.setState({ contactFormError4: 'Please fill in your work email' })
        } else this.setState({ contactFormError4: null })

        // if (data.message?.length < 25) return this.setState({ contactFormMessageError4: 'Please use at least 25 letters' });
        // else this.setState({ contactFormMessageError4: null })
        data.lang = this.props.lang
        data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
        data.bookADemo = true
        data.page = this.props.lang === "en" ? "home page" : "startsida"
        data.token = this.props.recaptchaToken
        data.isMobile = this.props.isMobile
        data.osMobileNameUA = this.props.osMobileNameUA
        data.browserName = this.props.browserName,
          data.browserNameUA = this.props.browserNameUA
        this.setState({
          loading2: true
        }, () => {

          fetch(API_ENDPOINT + '/data/contact/new', {
            method: 'POST',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({ data })
          }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error })
            } else {
              this.setState({
                supportForm: false
              }, () => {
                const path = 'thank-you';
                this.props[0].history.push(`/${path}`);
              })

            }
          })

        })
      }
    })
    this.props.updateToken();

  }


  listenToScroll = () => {
    const yScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({
      yScroll: yScroll,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", this.listenToScroll);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps[0].location.pathname != this.props[0].location.pathname) {

    //   this.setState({
    //     mobileMenu:false
    //   })

    // }
    // if (prevProps[0].location.hash != this.props[0].location.hash && this.props[0].location.hash) {

    //   this.setState({
    //     mobileMenu:false
    //   })

    // }


  }

  updateWindowDimensions() {
    if (typeof window !== "undefined")
      this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  logout = () => {
    localStorage.removeItem("token");
    window.location.reload(false);
  };

  toggleDropdown = () => {
    if (this.state.apiName !== 'resources')
      fetch(API_ENDPOINT + "/data/pages/" + this.state.apiName, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }).then(parseJSON).then(({ result, status }) => {
        if (result.error) {
          this.setState({ error: result.error });
        } else {
          this.setState(
            {
              dropdownOpen: !this.state.dropdownOpen,
              children: [...result],
            },
            () => {
              let children = [...this.state.children];
              for (let i = 0; i < this.state.children.length; i++) {
                // if (children[i].name["en"] === "Go Flow") {
                //   children[i].position = 1;
                // }
                // if (children[i].name["en"] === "Partners") {
                //   children[i].position = 5;
                // }

                if (
                  this.state.children[i].section &&
                  this.state.children[i].section.length
                ) {
                  for (
                    let m = 0;
                    m < this.state.children[i].section.length;
                    m++
                  ) {
                    if (
                      this.state.children[i].section[m] &&
                      this.state.children[i].section[m].item &&
                      this.state.children[i].section[m].item[0] &&
                      this.state.children[i].section[m].item[0].isTitleLink &&
                      this.state.children[i].section[m].item[0].title &&
                      this.state.children[i].section[m].item[0].titleLinkText
                    ) {
                      children.splice(i + 1, 0, {
                        name: this.state.children[i].section[m].item[0].title,
                        title:
                          this.state.children[i].section[m].item[0]
                            .titleLinkText,
                        alias: this.state.children[i].alias,
                        sectionID: "section" + m,
                        additionalLink: true,
                      });
                    }
                  }
                }
              }
              this.setState({
                children,
              });

              if (this.state.apiName === "all-aboutus") {
                let children = this.state.children;
                for (let i = 0; i < this.state.otherAboutUsLinks.length; i++) {
                  children.push({
                    ...this.state.otherAboutUsLinks[i],
                  });
                }
                children.sort((a, b) => {
                  return a.position - b.position;
                });
                this.setState({
                  children,
                });
              }
            }
          );
        }
      });

    if (this.state.apiName === 'resources') {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen,
        children: [...this.state.resourcesLinks]
      })
    }
  };

  //   sendMessage1 = (data) => {
  //     if (data.checked) {
  //         data.lang = this.props.lang
  //         this.setState({
  //             loading1: true
  //         }, () => {

  //             fetch(API_ENDPOINT + '/data/contact/new', {
  //                 method: 'POST',
  //                 headers: {
  //                     'content-type': 'application/json'
  //                 },
  //                 body: JSON.stringify({ data })
  //             }).then(parseJSON).then(({ result, status }) => {
  //                 if (result.error) {
  //                     this.setState({ error: result.error })
  //                 } else {
  //                     this.setState({
  //                         supportForm: false
  //                     },()=>{
  //                         const path = 'thank-you'.translate(this.props.lang);
  //                         this.props[0].history.push(`/${path}`);
  //                     })
  //                 }

  //             })
  //         })
  //     }
  // }

  render() {

    return (
      <div className={this.state.yScroll > 5 ? "nav nav-fixed" : "nav"}>

        {
          this.state.supportForm ?
            <div className='overlay overlay-active' onClick={() => {
              this.setState({
                supportForm: false
              })
            }}></div>
            :
            null
        }

        {/*-------------------------------------------------------book a demo form --------------------------------------------*/}


        <div className={this.state.supportForm ? 'contact-popup open' : 'contact-popup'}>
          <span className="close-icon" onClick={() => {
            this.setState({
              supportForm: false
            })
          }}>x</span>
          {
            !this.state.loading3 && !this.state.supportStatus ?
              <SupportForm isMobile={this.props.isMobile} onSubmit={this.sendMessage17} lang={this.props.lang} contactFormError={this.state.contactFormError4} contactFormMessageError={this.state.contactFormMessageError4} />
              : null
          }
          {
            this.state.loading3 ?
              <Player
                ref={this.player}
                autoplay={true}
                onEvent={(event) => {
                  if (event == 'complete') {
                    this.setState({
                      supportForm: false,
                      loading3: false
                    })
                  }
                }}
                //keepLastFrame={true}
                //loop={false}
                controls={true}
                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                style={{ height: '300px', width: '300px' }}
              ></Player>
              :
              null
          }
          {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
        </div>

        {/*-------------------------------------------------------end book a demo form --------------------------------------------*/}




        <div className="nav-content">
          <a href={this.props.lang === 'en' ? '/?lang=en' : '/'} className="logo">
            <img src={logo} style={{ width: 103, height: 20 }} />
          </a>
          {/* {this.state.width && this.state.width < 1275 ? ( */}
          <div className="mobile-flags-new">

            {/* 
            <div onClick={() => {
              if (this.props.lang === 'en') {
                this.props.changeLang('se')
              } else if (this.props.lang === 'se') {
                this.props.changeLang('en')
              }
            }}>
              {this.props.lang === 'se' ? <img src={sweflag} className="lan-flag" /> : <img src={engflag} className="lan-flag" />}
            </div> */}



            {/* {
                  this.state.mobilni ?
                    <ul>

                      <li onClick={() => {

                        this.setState({
                          mobilni: false,
                          mobileMenu: false,
                        }, () => {
                          this.props.setLang("en")
                        });



                      }}><img src={engflag} className="lan-flag" /></li>

                      <li onClick={() => {
                        this.setState({
                          mobilni: false,
                          mobileMenu: false,
                        }, () => {
                          this.props.setLang('se')
                        });




                      }}><img src={sweflag} className="lan-flag" /></li>
                    </ul> :
                    null
                } */}



            <div>
              <img
                src={bars}
                alt="bars"
                className="menu-icon"
                onClick={() => {
                  this.setState(
                    {
                      mobileMenu: !this.state.mobileMenu,
                    },
                    () => {
                      if (this.state.mobileMenu) {
                        this.props.homePageZIndexChange(this.state.mobileMenu);
                      }
                    }
                  );
                }}
              />
            </div>
          </div>
          {/* ) : null} */}


          <ul style={{ padding: 13 }}
            onMouseLeave={() => {
              this.setState({
                apiName: "",
                children: [],
                dropdownOpen: false,
              });
            }}
            className={
              this.state.mobileMenu ? "nav-list nav-list-opened" : "nav-list"
            }
          >
            <li
              onMouseEnter={() => {
                if (!this.state.mobileMenu) {
                  if (this.state.apiName === "all-solution") {
                    // this.setState({
                    //   apiName: "",
                    //   children: [],
                    //   dropdownOpen: false,
                    // });

                  } else {
                    this.setState(
                      {
                        apiName: "all-solution",
                        children: [],
                        dropdownOpen: false,
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }

              }}
            >
              <div
                onClick={() => {
                  if (this.state.apiName === "all-solution") {
                    this.setState({
                      apiName: "",
                      children: [],
                      dropdownOpen: false,
                      mobilni: false
                    });
                  } else {
                    this.setState(
                      {
                        apiName: "all-solution",
                        children: [],
                        dropdownOpen: false,
                        mobilni: false
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }}
              >
                <div className={`custom-dropdown ${this.state.apiName && this.state.apiName === 'all-solution' ? 'nav-blue-color' : ''}`}>
                  {"Our solution".translate(this.props.lang)}
                  <span
                    className={
                      this.state.dropdownOpen &&
                      this.state.apiName === "all-solution" &&
                      "rotate-arrow"
                    }
                  >
                    <Isvg src={icon} />
                  </span>
                </div>
                {this.state.dropdownOpen &&
                  this.state.apiName === "all-solution" ? (
                  <ul
                    onMouseLeave={() => {
                      if (this.state.apiName === "all-solution") {
                        this.setState({
                          apiName: "",
                          children: [],
                          dropdownOpen: false,
                        });
                      }
                    }}
                    className="dropdown-items1"
                  >
                    {this.state.children && this.state.children.map((item) => {
                      if (item && item.alias && item.alias[this.props.lang]) {
                        return (
                          <li
                            onClick={() => {
                              this.setState({
                                mobileMenu: false,
                              });
                            }}
                          >
                            <Link
                              lang={this.props.lang}
                              to={
                                !item.additionalLink
                                  ? "/var-plattform" +
                                  item.alias[this.props.lang]
                                  : item.additionalLink
                                    ? "/var-plattform" +
                                    item.alias[this.props.lang] +
                                    `#${item.sectionID}`
                                    : ""
                              }
                            >
                              {item.name && typeof item.name === "object"
                                ? item.name[this.props.lang]
                                : item.name
                                  ? item.name.translate(this.props.lang)
                                  : ""}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                ) : null}
              </div>
            </li>
            <li
              onMouseEnter={() => {
                if (!this.state.mobileMenu) {
                  if (this.state.apiName === "all-weassist") {
                    // this.setState({
                    //   apiName: "",
                    //   children: [],
                    //   dropdownOpen: false,
                    // });
                  } else {
                    this.setState(
                      {
                        apiName: "all-weassist",
                        children: [],
                        dropdownOpen: false,
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }

              }}
            >
              <div
                onClick={() => {
                  if (this.state.apiName === "all-weassist") {
                    this.setState({
                      apiName: "",
                      children: [],
                      dropdownOpen: false,
                      mobilni: false
                    });
                  } else {
                    this.setState(
                      {
                        apiName: "all-weassist",
                        children: [],
                        dropdownOpen: false,
                        mobilni: false
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }}
              >
                <div className={`custom-dropdown ${this.state.apiName && this.state.apiName === 'all-weassist' ? 'nav-blue-color' : ''}`}>
                  {"We assist".translate(this.props.lang)}{" "}
                  <span
                    className={
                      this.state.dropdownOpen &&
                      this.state.apiName === "all-weassist" &&
                      "rotate-arrow"
                    }
                  >
                    <Isvg src={icon} />
                  </span>
                </div>
                {this.state.dropdownOpen &&
                  this.state.apiName === "all-weassist" ? (
                  <ul
                    onMouseLeave={() => {
                      if (this.state.apiName === "all-weassist") {
                        this.setState({
                          apiName: "",
                          children: [],
                          dropdownOpen: false,
                        });
                      }
                    }}
                    className="dropdown-items1"
                  >
                    {this.state.children &&
                      this.state.children.map((item) => {
                        if (item && item.alias && item.alias[this.props.lang]) {
                          return (
                            <li
                              onClick={() => {
                                this.setState({
                                  mobileMenu: false,
                                });
                              }}
                            >
                              <Link
                                lang={this.props.lang}
                                to={"/vi-hjalper" + item.alias[this.props.lang]}
                              >
                                {item.name && typeof item.name === "object"
                                  ? item.name[this.props.lang]
                                  : ""}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                ) : null}
              </div>
            </li>
            {/* <li
              onClick={() => {
                this.setState({
                  mobileMenu: false,
                });
              }}
            >
              <Link lang={this.props.lang} to="/kunders-feedback-pa-Curoflow-digital-vardplattform">
                {"Customers tell us".translate(this.props.lang)}
              </Link>
            </li> */}
            <li
              onMouseEnter={() => {
                if (!this.state.mobileMenu) {
                  if (this.state.apiName === "resources") {
                    // this.setState({
                    //   apiName: "",
                    //   children: [],
                    //   dropdownOpen: false,
                    // });
                  } else {
                    this.setState(
                      {
                        apiName: "resources",
                        children: [],
                        dropdownOpen: false,
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }

              }}
            >
              <div
                onClick={() => {
                  if (this.state.apiName === "resources") {
                    this.setState({
                      apiName: "",
                      children: [],
                      dropdownOpen: false,
                      mobilni: false
                    });
                  } else {
                    this.setState(
                      {
                        apiName: "resources",
                        children: [],
                        dropdownOpen: false,
                        mobilni: false
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }}
              >
                <div className={`custom-dropdown ${this.state.apiName && this.state.apiName === 'resources' ? 'nav-blue-color' : ''}`}>
                  {"Insights".translate(this.props.lang)}{" "}
                  <span
                    className={
                      this.state.dropdownOpen &&
                      this.state.apiName === "resources" &&
                      "rotate-arrow"
                    }
                  >
                    <Isvg src={icon} />
                  </span>
                </div>
                {this.state.dropdownOpen &&
                  this.state.apiName === "resources" ? (
                  <ul
                    onMouseLeave={() => {
                      if (this.state.apiName === "resources") {
                        this.setState({
                          apiName: "",
                          children: [],
                          dropdownOpen: false,
                        });
                      }
                    }}
                    className="dropdown-items1"
                  >
                    {this.state.children &&
                      this.state.children.map((item) => {
                        if (item && item.link && item.link.indexOf("https://") !== -1) {
                          return (
                            <li>
                              <a
                                href={
                                  item &&
                                    item.link &&
                                    item.link.indexOf("https://") !== -1
                                    ? item.link
                                    : ""
                                }
                                target="_blank"
                              >
                                {item.name && typeof item.name === "object"
                                  ? item.name[this.props.lang]
                                  : item.name
                                    ? item.name.translate(this.props.lang)
                                    : ""}
                              </a>
                            </li>
                          );
                        } else if (
                          item &&
                          item.alias &&
                          item.alias[this.props.lang]
                        ) {
                          return (
                            <li
                              onClick={() => {
                                this.setState({
                                  mobileMenu: false,

                                });
                              }}
                            >
                              <Link
                                lang={this.props.lang}
                                to={"/foretaget" + item.alias[this.props.lang]}
                              >
                                {item.name && typeof item.name === "object"
                                  ? item.name[this.props.lang]
                                  : item.name
                                    ? item.name.translate(this.props.lang)
                                    : ""}
                              </Link>
                            </li>
                          );
                        } else if (item.special) {
                          return (
                            <li
                              onClick={() => {
                                this.setState({
                                  mobileMenu: false,
                                });
                              }}
                            >
                              <Link lang={this.props.lang} to={item.link}>
                                {item.name
                                  ? item.name.translate(this.props.lang)
                                  : ""}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                ) : null}
              </div>
            </li>
            <li
              onMouseEnter={() => {
                if (!this.state.mobileMenu) {
                  if (this.state.apiName === "all-aboutus") {
                    // this.setState({
                    //   apiName: "",
                    //   children: [],
                    //   dropdownOpen: false,
                    // });
                  } else {
                    this.setState(
                      {
                        apiName: "all-aboutus",
                        children: [],
                        dropdownOpen: false,
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }

              }}
            >
              <div
                onClick={() => {
                  if (this.state.apiName === "all-aboutus") {
                    this.setState({
                      apiName: "",
                      children: [],
                      dropdownOpen: false,
                      mobilni: false
                    });
                  } else {
                    this.setState(
                      {
                        apiName: "all-aboutus",
                        children: [],
                        dropdownOpen: false,
                        mobilni: false
                      },
                      () => {
                        this.toggleDropdown();
                      }
                    );
                  }
                }}
              >
                <div className={`custom-dropdown ${this.state.apiName && this.state.apiName === 'all-aboutus' ? 'nav-blue-color' : ''}`}>
                  {"Company".translate(this.props.lang)}{" "}
                  <span
                    className={
                      this.state.dropdownOpen &&
                      this.state.apiName === "all-aboutus" &&
                      "rotate-arrow"
                    }
                  >
                    <Isvg src={icon} />
                  </span>
                </div>
                {this.state.dropdownOpen &&
                  this.state.apiName === "all-aboutus" ? (
                  <ul
                    onMouseLeave={() => {
                      if (this.state.apiName === "all-aboutus") {
                        this.setState({
                          apiName: "",
                          children: [],
                          dropdownOpen: false,
                        });
                      }
                    }}
                    className="dropdown-items1"
                  >
                    {this.state.children &&
                      this.state.children.map((item) => {
                        if (
                          item &&
                          item.link &&
                          item.link.indexOf("https://") !== -1
                        ) {
                          return (
                            <li>
                              <a
                                href={
                                  item &&
                                    item.link &&
                                    item.link.indexOf("https://") !== -1
                                    ? item.link
                                    : ""
                                }
                                target="_blank"
                              >
                                {item.name && typeof item.name === "object"
                                  ? item.name[this.props.lang]
                                  : item.name
                                    ? item.name.translate(this.props.lang)
                                    : ""}
                              </a>
                            </li>
                          );
                        } else if (
                          item &&
                          item.alias &&
                          item.alias[this.props.lang]
                        ) {
                          return (
                            <li
                              onClick={() => {
                                this.setState({
                                  mobileMenu: false,

                                });
                              }}
                            >
                              <Link
                                lang={this.props.lang}
                                to={"/foretaget" + item.alias[this.props.lang]}
                              >
                                {item.name && typeof item.name === "object"
                                  ? item.name[this.props.lang]
                                  : item.name
                                    ? item.name.translate(this.props.lang)
                                    : ""}
                              </Link>
                            </li>
                          );
                        } else if (item.special) {
                          return (
                            <li
                              onClick={() => {
                                this.setState({
                                  mobileMenu: false,
                                });
                              }}
                            >
                              <Link lang={this.props.lang} to={item.link}>
                                {item.name
                                  ? item.name.translate(this.props.lang)
                                  : ""}
                              </Link>
                            </li>
                          );
                        }
                      })}
                  </ul>
                ) : null}
              </div>
            </li>

            <li
              className="mobile-v"
              onClick={() => {
                this.setState({
                  // mobileMenu: false, 
                });
              }}
            >
              <span>
                <Link lang={this.props.lang} to="/kontakta-oss">
                  {"Contact us".translate(this.props.lang)}
                </Link>
              </span>
            </li>
            {/* <li className="mobile-v">


              <div className="mob-lang">
                <div onClick={() => { this.setState({ mobilni: !this.state.mobilni, dropdownOpen: false }); }}>
                  {this.props.lang === 'en' ? <img src={engflag} className="lan-flag" /> : <img src={sweflag} className="lan-flag" />} <span className={`${this.state.mobilni ? 'rotate-arrow' : ""} `} ><Isvg src={icon} /></span>
                </div>



                {
                  this.state.mobilni ?
                    <ul>

                      <li onClick={() => {

                        this.setState({
                          mobilni: false,
                          mobileMenu: false,
                        }, () => {
                          this.props.setLang("en")
                        });



                      }}><img src={engflag} className="lan-flag" /></li>

                      <li onClick={() => {
                        this.setState({
                          mobilni: false,
                          mobileMenu: false,
                        }, () => {
                          this.props.setLang('se')
                        });




                      }}><img src={sweflag} className="lan-flag" /></li>
                    </ul> :
                    null
                }


              </div>
       
            </li> */}
            <li className="mob-bookademo">
              <button className='slider-button' onClick={() => {
                this.setState({
                  supportForm: true,
                  mobileMenu: false
                })
              }}>{'Book a demo'.translate(this.props.lang)}
              </button>
            </li>
            {/* <span>
                                    <Link lang={this.props.lang} to="/gdpr">{'GDPR'.translate(this.props.lang)}</Link>
                                </span> */}

            {/* {
                                typeof window !== 'undefined' && window.innerWidth < 768 ?

                                    <div className="nav-help mobile-nav-help">
                                 

                                        <span onClick={() => {this.props.changeLang(); }}> {this.props.lang == 'en' ? 'Se' : 'Eng'} </span>
                                    </div>

                                    :

                                    null
                            } */}
          </ul>

          <div className="lan-cont">

            {/* <span>
              <Input
                type="select"
                value={this.props.lang}
                onChange={(e) => {
                  this.props.changeLang(e.target.value);
             
                }}
              >
                <option value={"en"}>ENG</option>
                <option value={"se"}>SWE</option>
              </Input>
              <Isvg src={icon} />
            </span> */}


            <button className='slider-button' onClick={() => {
              this.setState({
                supportForm: true
              })
            }}>{'Book a demo'.translate(this.props.lang)}
            </button>

            <span className="contact-us-c">
              {/* {this.props[0].location.pathname === "/" ||
                this.props[0].location.pathname === "/en" ? (
                <Link lang={this.props.lang} to="/kontakta-oss">
                  {"Contact us".translate(this.props.lang)}
                </Link>
              ) : (
                <button className="book-demo-btn" onClick={() => {
                  this.props.bookDemo(true)
                }}>{"Book a demo".translate(this.props.lang)}</button>
              )} */}
              <Link lang={this.props.lang} to="/kontakta-oss">
                {"Contact us".translate(this.props.lang)}
              </Link>
            </span>
          </div>
        </div>

      </div>
    );
  }
}

export default Nav;
